<template>
  <div>
    <van-empty
      v-if="!addresses || addresses.length === 0"
      :description="$t('No addresses')"
      image-size="200px"
    />
    <van-list v-model="loading" :finished="true" @load="onLoad">
      <div v-for="address in addresses" :key="address.id" class="address-item">
        <div class="item-detail" @click="chooseAddress(address)">
          <div class="address-name">{{ address.name }} {{ address.phone }}</div>
          <div class="address-address">
            {{ address.address1 }} <br />{{ address.address2 }}
          </div>
        </div>
        <div style="display: flex; align-items: center">
          <van-icon
            name="edit"
            style="padding: 10px"
            @click="toEdit(address)"
          />
          <div class="vertical-divider"></div>
          <van-icon
            name="delete-o"
            style="padding: 10px"
            @click="toDelete(address.id)"
          />
        </div>
      </div>
    </van-list>
    <div class="new-address mobile-fixed-theme" @click="toCreate()">
      {{ $t("Add Address") }}
    </div>
  </div>
</template>

<script>
import { List, Toast, Empty, Icon, Dialog } from "vant";
import { getAddresses, customerAddressDelete } from "./service";
import { getShopifyCustomer } from "@/utils/token";

export default {
  components: {
    [List.name]: List,
    [Empty.name]: Empty,
    [Icon.name]: Icon,
  },
  data() {
    return {
      loading: false,
      addresses: [],
      origin: this.$route.query.origin,
    };
  },
  methods: {
    onAdd() {},
    onLoad() {},
    chooseAddress(address) {
      if ("order" !== this.origin) return;
      this.$store.commit("setOrderAddress", address);
      this.$router.go(-1);
    },
    toEdit(address) {
      this.$router.push({ name: "address", params: { address } });
    },
    toDelete(addressId) {
      Dialog.confirm({
        title: "",
        message: this.$t("确定删除地址吗？"),
        cancelButtonText: this.$t("取消"),
        confirmButtonText: this.$t("确定"),
      })
        .then(() => {
          // on confirm
          this.customerAddressDelete(addressId);
        })
        .catch(() => {
          // on cancel
        });
    },
    async customerAddressDelete(addressId) {
      const shopifyCustomer = getShopifyCustomer();
      const { shopifyCustAccessToken } = shopifyCustomer;
      const payload = {
        customerAccessToken: shopifyCustAccessToken,
        id: addressId,
      };

      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });
      const { customerAddressDelete: data } = await customerAddressDelete(
        payload
      );
      const { deletedCustomerAddressId, customerUserErrors } = data;
      Toast.clear();

      if (deletedCustomerAddressId) {
        Toast.success(this.$t("地址已删除"));
        this.getAddresses();
      }

      if (customerUserErrors && customerUserErrors.length > 0) {
        const { message } = customerUserErrors[0];
        Toast.fail(message);
      }
    },
    toCreate() {
      this.$router.push({ name: "address" });
    },
    async getAddresses() {
      const shopifyCustomer = getShopifyCustomer();
      const { shopifyCustAccessToken } = shopifyCustomer;
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });
      const { data } = await getAddresses(shopifyCustAccessToken);
      const { customer } = data;
      Toast.clear();
      const { edges } = customer.addresses;

      const addresses = edges.map((item) => {
        return item.node;
      });
      this.addresses = addresses;
    },
  },
  mounted() {
    this.getAddresses();
  },
};
</script>

<style lang="less" scoped>
@primaryColor: #ee502f;

.new-address {
  position: fixed;
  bottom: 10px;
  left: 15px;
  right: 15px;
  background-color: @primaryColor;
  color: white;
  text-align: center;
  line-height: 40px;
  height: 40px;
  border-radius: 20px;
}

.address-item {
  background-color: white;
  border-radius: 5px;
  margin: 10px 15px;
  padding: 15px;
  display: flex;
  align-items: center;
}

.item-detail {
  flex: 1;
}

.address-name {
  color: #333;
}

.address-address {
  color: #999;
  font-size: 0.9rem;
  padding-top: 10px;
}

.vertical-divider {
  width: 1px;
  height: 10px;
  display: inline-block;
  background-color: #999;
}
</style>